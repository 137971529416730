import DownloadInfo from './DownloadInfo'
import React from "react";

function Download(netid) {
   return (
        <React.Fragment>
           <DownloadInfo netid={netid.netid}/>
        </React.Fragment>
    );
}

export default Download;
