import Form from './Form'
import React from "react";

function Main() {

    return (
        <React.Fragment>
            <Form />
        </React.Fragment>

    );
}

export default Main;
