import UploadForm from './UploadForm'
import React from "react";
import { useLocation } from "react-router-dom";

function Upload(netid) {

    let location = useLocation();

    return (
        <UploadForm sfcid={location.pathname.split('/')[2]} netid={netid.netid} />
    );
}

export default Upload;
