import React from 'react'
import './App.css';

function Infoauth() {
    return (

        <div id="info">
            <p className="mt-4">
                Please log in to begin your upload.
            </p>
        </div>

    );
}

export default Infoauth;
