import React, { Component } from 'react';
import { Button } from "react-bootstrap";
import env from './env.json'
class CustomOAuthButton extends Component {
    signIn() {
        // The url of the Cognito Hosted UI
        const url = env.AWS_COGNITO_URL

        // Launch hosted UI
        window.location.assign(url);
    }

    render() {
        return (
            <Button bsstyle="primary" onClick={this.signIn}>Login</Button>
        )
    }
}

export default CustomOAuthButton;