import React, { Component } from "react";
import "./App.css";
import CustomOAuthButton from './CustomOAuthButton';
import Amplify, { Auth, Hub } from 'aws-amplify';
import config from "./config";
import { authz } from "./authorization";
import Routes from "./Routes";
import env from "./env.json";
import Mainauth from "./Mainauth";


const oauth = {
    domain: config.cognito.DOMAIN,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: config.cognito.REDIRECT_SIGNIN,
    redirectSignOut: config.cognito.REDIRECT_SIGNOUT,
    responseType: 'code' // or token
};


Amplify.configure({
    Auth: {
        oauth: oauth,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
        identityPoolId: env.IDENTITY_POOL_ID,

    },
    Storage: {
        bucket: env.BUCKET,
        region: env.REGION,
        identityPoolId: env.IDENTITY_POOL_ID
    },
    API: {
        endpoints: [          
            {
                name: "authz",
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION
            }
        ]
    }
    
});

class App extends Component {

    constructor(props) {
        super(props);
        this.onHubCapsule = this.onHubCapsule.bind(this);
        this.signOut = this.signOut.bind(this);
        this.refreshAuthNZ = this.refreshAuthNZ.bind(this);
        // let the Hub module listen on Auth events
        Hub.listen('auth', this);
        this.state = {
            authn: 'loading',
        }
    }

    componentDidMount() {

        // check the current user when the App component is loaded
        Auth.currentAuthenticatedUser().then(user => {
            authz().then(res => {
                this.refreshAuthNZ({
                    data: {
                        authn: 'signedIn',
                        authz: res,
                        netid: user.attributes.email
                    }
                });
                
                // check for stored redirect if we are signed in 
                const redirectURL = localStorage.getItem('redirect');

                if (redirectURL) {
                    if (redirectURL !== window.location.href.toString()) {
                        localStorage.removeItem("redirect");
                        window.location.assign(redirectURL);
                    }                    
                }
            });
        }).catch(() => {

            this.refreshAuthNZ({
                data: {
                    authn: 'signIn',
                    authz: false,
                    netid: 'default'
                }
            });

            // Store redirect in local storage
            localStorage.setItem('redirect', window.location.href);
        });
    }

    onHubCapsule(capsule) {
        // The Auth module will emit events when user signs in, signs out, etc
        const {channel, payload} = capsule;
        if (channel === 'auth') {
            switch (payload.event) {
                case 'signIn':
                    Auth.currentAuthenticatedUser().then(user => {
                    authz().then(res => {
                        this.refreshAuthNZ({
                            data: {
                                authn: 'signedIn',
                                authz: res,
                                netid: user.attributes.email
                            }
                        });
                    });
                    }).catch(() => {
                        this.refreshAuthNZ({
                            data: {
                                authn: 'signIn',
                                authz: false,
                                netid: 'default'
                            }
                        });
                    });
                    break;
                case 'signIn_failure':
                    this.refreshAuthNZ({
                        data: {
                            authn: 'signIn',
                            authz: false,
                            netid: ''
                        }
                    });
                    break;
                default:
                    break;
            }
        }
    }

    signOut() {
        Auth.signOut().then(() => {
            this.refreshAuthNZ({
                data: {
                    authn: 'signIn',
                    authz: false,
                    netid: ''
                }
            });
        })
    }

    // refresh user state
    refreshAuthNZ(res) {
        if (res.data.netid !== undefined) {
            this.setState({ authn: res.data.authn, authz: res.data.authz, netid: res.data.netid })
        }
        else {
            this.setState({ authn: res.data.authn, authz: res.data.authz, netid: "default" })
        }
        
    }

    // {authn === 'signIn' && <Routes childProps={{ authn: this.state.authn, authz: this.state.authz, netid: this.state.netid }} />}
    // {authn === 'signIn' && <Mainauth />}

    // Replace second signin authn with the line above

    render() {
        const { authn } = this.state;

        // TODO: Cullen do a code review here to see if you think this will work instead of toggling commented lines above.
        if (process.env.NODE_ENV !== "development") {
            return (
                authn !== 'loading' &&
                <div className="App" >
                    {authn === 'signIn' && <CustomOAuthButton />}
                    {authn === 'signIn' && <Mainauth />}
                    {authn === 'signedIn' && <Routes childProps={{ authn: this.state.authn, authz: this.state.authz, netid: this.state.netid }} />}
                </div>          
            );
        } else {
            return (
                <div className="App">
                    {authn === 'signIn' && <Routes childProps={{ authn: this.state.authn, authz: this.state.authz, netid: this.state.netid }} />}
                </div>
            )
        }
    }
}

export default App;
