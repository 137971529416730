import React from "react";
import './App.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import env from "./env.json";
   
const headers = {
    'X-SDC-APPLICATION-ID': env.APPID
}

export default class Form extends React.Component {

    state = {
        campaigns: []
    }
    
    componentDidMount() {
        let baseUrl = env.SSDC_CLM_LIST_URL        
        axios.get(baseUrl, {
            headers: headers
        }).then(res => {                
            const campaigns = res.data.data;
            this.setState({ campaigns });
        })
    }

    render() {

        return (

            <div style={{ paddingTop: '2%' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>Contact Import</h1>

                            <div id="root">
                                <div className="App">
                                    <div id="info">
                                        <p>Please select the campaign you wish to import contacts into.</p>
                                    </div>

                                    <table className="table table-striped">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">#</th>
                                                <th>Campaign</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                this.state.campaigns.map((camp, index) => (
                                                 <tr key= {camp.Id}>
                                                        <th scope="row">{index + 1}</th>
                                                <td>
                                                    <Link  to={{
                                                            pathname: `/upload/${camp.Id}`,
                                                            state: camp.Id
                                                        }}  >
                                                            {camp.Name}
                                                        </Link>
                                                </td>
                                                </tr>

                                                ))

                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
