import Infoauth from './Infoauth'
import React from "react";

async function signIn() {
}

function Mainauth() {

    signIn()

    return (
        <React.Fragment>
            <Infoauth />    
        </React.Fragment>
    );
}

export default Mainauth;
