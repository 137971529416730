import React from 'react';
import env from "./env.json";

export default function CampaignInfo(props) {
    let campaign_url = env.SF_CAMPAIGN_INFO_URL
    let url = campaign_url + props.sfcid + "/view";
    if (!props.loaded) {
        
        return (
            <div>
                Loading...
            </div>
        )
        
    } else {

        return (
            <div>
                <a href={url}>{props.names[props.sfcid]}</a>
            </div>
        )
    }
}