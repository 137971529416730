const dev = {
    apiGateway: {
        REGION: "us-west-2",
        URL: "https://jl4bwhure4.execute-api.us-west-2.amazonaws.com/beta/-authz/"
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_Dfous2iGv",
        APP_CLIENT_ID: "66eg76166nt1s060loi57unsvc",
        DOMAIN: "clm.auth.us-west-2.amazoncognito.com",
        REDIRECT_SIGNIN: "https://clm.dev.trellis.arizona.edu/",
        REDIRECT_SIGNOUT: "https://shibboleth.arizona.edu/cgi-bin/logout.pl"
  }
};

const stage = {
    apiGateway: {
        REGION: "us-west-2",
        URL: "https://jl4bwhure4.execute-api.us-west-2.amazonaws.com/beta/-authz/"
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_Dfous2iGv",
        APP_CLIENT_ID: "66eg76166nt1s060loi57unsvc",
        DOMAIN: "clm.auth.us-west-2.amazoncognito.com",
        REDIRECT_SIGNIN: "https://clm.stage.trellis.arizona.edu/",
        REDIRECT_SIGNOUT: "https://shibboleth.arizona.edu/cgi-bin/logout.pl"
    }
};

const prod = {
    apiGateway: {
        REGION: "us-west-2",
        URL: "https://jl4bwhure4.execute-api.us-west-2.amazonaws.com/beta/-authz"
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_Dfous2iGv",
        APP_CLIENT_ID: "66eg76166nt1s060loi57unsvc",
        DOMAIN: "clm.auth.us-west-2.amazoncognito.com",
        REDIRECT_SIGNIN: "https://clm.trellis.arizona.edu/",
        REDIRECT_SIGNOUT: "https://shibboleth.arizona.edu/cgi-bin/logout.pl"
    }
};

// const config = process.env.REACT_APP_STAGE === 'dev'
//   ? prod
//   : dev;

// This is a bit of a hack
let current_stage = process.env.REACT_APP_STAGE
let stage_config = {}

if (current_stage === 'dev') {
        stage_config = dev
} else if (current_stage === 'stage' ) {
        stage_config = stage
} else if (current_stage === 'prd') {
        stage_config = prod
}

const config = stage_config

export default {
    apiGateway: config.apiGateway,
    cognito: config.cognito
}; 