import { Auth, API } from 'aws-amplify';

export const authz = () => {
    return Auth.currentSession().then(session => {
        // const token = session.idToken.jwtToken;
        // let myInit = { // OPTIONAL
        //     headers: {
        //         Authorization: token,
        //         "Access-Control-Allow-Origin": "*"
        //     }
        // }
        return true;
    });
}

export const authz2 = () => {
    return Auth.currentSession().then(session => {
        const token = session.idToken.jwtToken;
        let myInit = { // OPTIONAL
            headers: {
                Authorization: token,
                "Access-Control-Allow-Origin": "*"
            }
        }
        return API.get("authz", "/authz", myInit).then(response => {
            return true;
        }).catch(error => {
            return false;
        });
    }).catch(error => {
        return false;
    });
}
