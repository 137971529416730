import React from "react";
import Main from "./Main";
import Upload from "./Upload";
import Download from "./Download";
import {
    Switch,
    Route
} from "react-router-dom";

import AppliedRoute from "./AppliedRoute";

export default ({ childProps }) =>
    <Switch>
        <AppliedRoute path="/upload/" children={<Upload netid={childProps.netid}/> } />
        <AppliedRoute path="/download" children={<Download netid={ childProps.netid } /> } />
        <AppliedRoute path="/" children={<Main />} props={childProps} />
        
        { /* Finally, catch all unmatched routes */}
        <Route  />
    </Switch>;
