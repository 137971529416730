import React from 'react'
import './App.css';
import axios from 'axios';
import Storage from '@aws-amplify/storage';
import env from "./env.json";
import CampaignInfo from './CampaignInfo'

const headers = {
    'X-SDC-APPLICATION-ID': env.APPID
}

export function SetS3Config(bucket, level) {
    Storage.configure({
        bucket: bucket,
        level: level,
        region: 'us-west-2',
        identityPoolId: env.IDENTITY_POOL_ID
    });
}
export default class DownloadInfo extends React.Component {
    
    constructor(props) {
        super(props);        
        this.state = {
            campaign: [],
            sfcid: props.sfcid,
            netid: props.netid,
            status: "Loading Data",
            files: [],
            file: "",
            names1: [],
            namese: [],
            errorFiles: [],
            defaultName: "Loading",
            gotnames: false
        }
    }

    componentDidMount() {
        this.listFiles()
        this.listFileErrors()
    }

    getCampaignNames = async function () {
        const names = {}
        await Promise.all(this.state.files.map(f => {
            const sfcid = decodeURI(f).split('/')[6].split('-')[0];
            if (!names.hasOwnProperty(sfcid)) {
                names[sfcid] = null;
                let baseUrl = env.SSDC_CLM_FETCH_URL // "https://ssdc-dev.test.trellis.arizona.edu:18655/rel1/clm/fetch/?sdcApplicationId=trellis&sfcid="
                return axios.get(baseUrl + sfcid, { 'headers': headers })
                    .then(res => {
                        const campaign = JSON.stringify(res.data);
                        names[sfcid] = campaign.split(",")[3].split(":")[1].replace(/['"]+/g, '');
                    }).catch(console.error)
            }
        }))

        this.setState({ names1: names });
  
    }

    getCampaignNamesErrors = async function () {
        const names = {}
        await Promise.all(this.state.errorFiles.map(f => {
            const sfcid = decodeURI(f).split('/')[6].split('-')[0];
            if (!names.hasOwnProperty(sfcid)) {
                names[sfcid] = null;
                let baseUrl = env.SSDC_CAMPAIGN_LIST_URL
                return axios.get(baseUrl + sfcid, { 'headers': headers })
                    .then(res => {
                        const campaign = JSON.stringify(res.data);
                        names[sfcid] = campaign.split(",")[3].split(":")[1].replace(/['"]+/g, '');
                    }).catch(console.error)
            }
        }))

        this.setState({ namese: names });
    }

    listFiles = async () => {
        SetS3Config(env.PROCESSED_S3_BUCKET)
        const files = await Storage.list(this.state.netid + "/download/")
        let signedFiles = files.map(f => Storage.get(f.key))
        signedFiles = await Promise.all(signedFiles)
        this.setState({ files: signedFiles })
        this.getCampaignNames().then(e => {
            console.log('set got normal names but set nothing' + this.state.gotnames);
        });
    }

    listFileErrors = async () => {
        SetS3Config(env.PROCESSED_S3_BUCKET)
        const files = await Storage.list(this.state.netid + "/errors/")
        let signedErrorFiles = files.map(f => Storage.get(f.key))
        signedErrorFiles = await Promise.all(signedErrorFiles)
        this.setState({ errorFiles: signedErrorFiles })
        this.getCampaignNamesErrors().then(e => {
            this.setState({ gotnames: true });
        });
    }

    selectFile = async file => {
        const signed = await Storage.get(file, {
            download: true,
            progressCallback(progress) {
                console.log(`Downloaded: ${progress.loaded}/${progress.total}`);
            }})
        this.setState({ file: signed })
       
    }
   
    render() {
        const loadedNames = this.state.gotnames;
        var months = ["Offset", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>Upload History</h1>

                        <div id="root">
                            <div className="App">

                                <table className="table table-striped">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th>Status</th>
                                            <th>Campaign</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {

                                            this.state.files.reverse().map((file, i) => (
                                                    <tr key={i}>
                                                    <th scope="row">{i + 1}</th>

                                                            <td>
                                                        Success:
                                                        <small>     {

                                                            decodeURIComponent(decodeURI(file).split('/')[6].split('-')[2].split('.')[0]).toString().substring(9, 11) + ":" +
                                                            decodeURIComponent(decodeURI(file).split('/')[6].split('-')[2].split('.')[0]).toString().substring(11, 13) + " " +
                                                            decodeURIComponent(decodeURI(file).split('/')[6].split('-')[2].split('.')[0]).toString().substring(13, 15) + " - " +
                                                            
                                                            months[parseInt(decodeURIComponent(decodeURI(file).split('/')[6].split('-')[2].split('.')[0]).toString().substring(0, 2))]+ " " +
                                                            decodeURIComponent(decodeURI(file).split('/')[6].split('-')[2].split('.')[0]).toString().substring(2, 4) + ", " +
                                                            decodeURIComponent(decodeURI(file).split('/')[6].split('-')[2].split('.')[0]).toString().substring(4, 8)
                                                            
                                                        }
                                                        <br></br>
                                                        <a href={file} target="_blank" rel='noreferrer'> Download Invalid Lines  </a></small>
                                                    </td>

                                                    <td>
                                                        {loadedNames ?  <CampaignInfo loaded={this.state.gotnames} names={this.state.names1} sfcid={decodeURI(file).split('/')[6].split('-')[0]}/> : "Loading"}
                                                    </td>
                                                        
                                                </tr>
      
                                            ))

                                        }

                                        {

                                            this.state.errorFiles.map((file, i) => (

                                                <tr key={i}>
                                                    <th scope="row">{i + 1}</th>

                                                    <td>
                                                        Error:
                                                        <small>     {
                                                            decodeURIComponent(decodeURI(file).split('/')[6].split('-')[2].split('.')[0]).toString().substring(9, 11) + ":" +
                                                            decodeURIComponent(decodeURI(file).split('/')[6].split('-')[2].split('.')[0]).toString().substring(11, 13) + " " +
                                                            decodeURIComponent(decodeURI(file).split('/')[6].split('-')[2].split('.')[0]).toString().substring(13, 15) + " - " +

                                                            months[parseInt(decodeURIComponent(decodeURI(file).split('/')[6].split('-')[2].split('.')[0]).toString().substring(0, 2))] + " " +
                                                            decodeURIComponent(decodeURI(file).split('/')[6].split('-')[2].split('.')[0]).toString().substring(2, 4) + ", " +
                                                            decodeURIComponent(decodeURI(file).split('/')[6].split('-')[2].split('.')[0]).toString().substring(4, 8)
                                                               }
                                                            <br></br>
                                                            <a href={file} target="_blank"> Download File  </a></small>
                                                    </td>

                                                    <td>
                                                        {loadedNames ? <CampaignInfo loaded={this.state.gotnames} names={this.state.namese} sfcid={decodeURI(file).split('/')[6].split('-')[0]} /> : "Loading"}
                                                    </td>

                                                </tr>


                                            ))

                                        }
                </tbody>
              </table>

            </div>
          </div>
                            </div>
                        </div>
                    </div>

        );
    }
}